export function changeEventImage() {
  if ($("body[class^='events_item']")[0] !== undefined) {
    if ($("#main_slide_wrapper")[0] !== undefined) {
      const imgSrc = $("#event_page_main_slide img").attr("src");
      $(".event_page_left").prepend(`<div class="inc__altern_img_events"><img src="${imgSrc}" alt=''></div>`);
    }

    if ($(".event_info_item_title.color_orange")[0] !== undefined) {
      const eventLocationInfo = $(".event_info_item_title.color_orange")[0].innerHTML.split(" ");
      const eventCity = eventLocationInfo.pop();

      if (eventLocationInfo.join(" ").includes(eventCity)) {
        if (eventLocationInfo.join(" ").split("")[eventLocationInfo.join(" ").split("").length - 1].trim() === ",") {
          const lettersOfInfo = eventLocationInfo.join(" ").split("");
          lettersOfInfo.pop();
          $(".event_info_item_title.color_orange")[0].innerHTML = lettersOfInfo.join("");
        }
      }
    }
  }
}

export function changeDateTimeFormat($dateTimeElement) {
  let text = $dateTimeElement.text().trim();
  let formattedDate = undefined;
  let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  let timeText = undefined;
  if (text.includes("-")) {
    let dates = text.match(/\d{2}\/\d{2}\/\d{4}/g);
    const date1Parts = dates[0].split("/");
    const date1Day = parseInt(date1Parts[0], 10);
    const month1 = date1Parts[1];

    const date2Parts = dates[1].split("/");
    const date2Day = parseInt(date2Parts[0], 10);
    const month2 = date2Parts[1];
    if (month1 == month2) {
      formattedDate = `${date1Day}-${date2Day} ${monthNames[parseInt(month1, 10) - 1]}`;
    } else {
      formattedDate = `${date1Day} ${monthNames[parseInt(month1, 10) - 1]}-${date2Day} ${monthNames[parseInt(month2, 10) - 1]}`;
    }
  } else {
    // Extract the date and time
    let dateText = text.split("•")[0].trim();
    // Convert date format
    let dateParts = dateText.split("/");

    let day = parseInt(dateParts[0], 10);
    let month = dateParts[1];
    let year = dateParts[2];

    // let formattedDate = `${day} ${monthNames[parseInt(month, 10) - 1]} ${year}`;
    formattedDate = `${day} ${monthNames[parseInt(month, 10) - 1]}`;
    // Convert time format
    timeText = $dateTimeElement.find("span").text().trim();
  }

  function formatTime(timeText) {
    if (timeText !== undefined) {
      // Split the timeText into hours and minutes
      let [hours, minutes] = timeText.split(":").map((value) => parseInt(value, 10));

      // If the time is exactly 00:01, return an empty string
      if (hours === 0 && minutes === 1) {
        return "";
      }

      // Convert to 12-hour format
      let period = hours >= 12 ? "pm" : "am";
      hours = hours % 12 || 12;

      // If minutes are 00, do not display the minutes
      if (minutes === 0) {
        return `${hours} ${period}`;
      }

      // Otherwise, include minutes in the formatted time
      return `${hours}:${minutes.toString().padStart(2, "0")} ${period}`;
    }
  }

  // let [hours, minutes] = timeText.split(":");
  // hours = parseInt(hours, 10);
  // let period = hours >= 12 ? "pm" : "am";
  // hours = hours % 12 || 12;
  let formattedTime = formatTime(timeText);

  return [formattedDate, formattedTime];
}

function makeAjaxCall() {
  const contentForFront = [];
  if (!$(".inc__event__groupped[data-activemonth='true']").attr("data-ajaxcomplete")) {
    $(".inc__new-calendar--wrapper").addClass("inc__ajax-loading");

    // Collect AJAX requests
    const requests = [];

    function ajaxGetContent(targetUrl, index) {
      // Create a new deferred object for each AJAX call
      const deferred = $.Deferred();

      $.ajax({
        url: targetUrl, // Gets the URL from the link's href attribute
        type: "GET",
        success: function (response) {
          var htmlContent = $("<div/>").html(response);
          const eventTitle = $(htmlContent).find(".event_page_left_title");
          const eventImg = $(htmlContent).find("#event_page_main_slide img");
          const eventDateTime = $(htmlContent).find(".event_info_item_title:not(.inc__event_info--name-city)");
          const venueName = $(htmlContent).find(".inc__event_info--name-city__name");
          const venueCity = $(htmlContent).find(".inc__event_info--name-city__city");
          const venueCountry = $(htmlContent).find(".inc__event_info--name-city__country");

          const locationParts = [
            venueName.html()?.trim() || "",
            venueCity.html()?.trim() ? ", " + venueCity.html().trim() : "",
            venueCountry.html()?.trim() ? ", " + venueCountry.html().trim() : "",
          ];

          const formattedLocation = locationParts.join("").trim();

          const ticketsLink = $(htmlContent).find(".event_page_left_inner .btn.btn-simple.btn-block");
          const festivalPass = $(htmlContent).find(".btn-fest-pass");

          const eventProgram = $(htmlContent).find(".event_page_content_inner");
          const eventLabel = $(htmlContent).find(".inc_event-label__in-item--label");
          const eventLabelID = $(htmlContent).find(".inc_event-label__in-item--label").attr("data-labelid");
          const [formattedDate, formattedTime] = changeDateTimeFormat(eventDateTime);
          // Create the content to append
          const contentToAppend = `
            <div class="inc__event-card" data-inctimecompare=''>
              <div class="inc__event-card__img">
                <img src="${$(eventImg).attr("src")}" alt="" />
              </div>
              <div class="concert-card__info">
             
              <div class="inc_event-label__in-item--label" data-labelid="${eventLabelID}">
              ${eventLabel.html() ? eventLabel.html() : ""}
              </div>
               <div class="inc__event-card__venue-info">
            ${formattedLocation}
              </div>
                <div class="inc__event-card__title">
  ${eventTitle.html()}
</div>
                <div class="inc__event-card__program">
                 <p class="inc__event-card__date">
  ${formattedDate}${formattedTime ? `, ${formattedTime}` : ""}
</p>
                  <div class="inc__event-card__location">
                    
                    
                  </div>
                  ${eventProgram.html()}
                </div>
                <div class="concert-card__action">
                  ${ticketsLink.length !== 0 ? ticketsLink.prop("outerHTML") : ""}
                  ${festivalPass.length !== 0 ? festivalPass.prop("outerHTML") : ""}

                </div>
              </div>
            </div>
          `;
          // removed the address of the venue
          // <p class="inc__event-card__venueName">${venueName.html() ? venueName.html() : ""}</p>;
          // <p class="inc__event-card__location2">${location2.html() ? location2.html() : ""}</p>

          // Store the content in the array at the correct index
          contentForFront[index] = contentToAppend;
          // Resolve the deferred object
          deferred.resolve();
        },
        error: function (xhr, status, error) {
          console.error(error);
          deferred.reject();
        },
      });

      // Push the deferred object to the requests array
      requests.push(deferred.promise());
    }

    if ($(".inc__event__groupped[data-activemonth='true']")[0] !== undefined) {
      console.log(".inc__event__groupped[data-activemonth='true']");
      $(".inc__event__groupped[data-activemonth='true']")
        .find(".events_item .events_item_link")
        .each(function (index, item) {
          const url = $(this).attr("href");
          console.log(url);
          ajaxGetContent(url, index);
        });

      // When all AJAX requests are done
      $.when.apply($, requests).done(function () {
        // Append all the content in order once all AJAX requests are complete
        $(".inc__event__groupped[data-activemonth='true']").attr("data-ajaxcomplete", true);

        $(".inc__event__groupped[data-activemonth='true']").append(`<div class="inc__event-card--wrapper">` + contentForFront.join("") + "</div>");
        $(".inc__new-calendar--wrapper").removeClass("inc__ajax-loading");
        $(".inc__event-card--wrapper").animate({ opacity: 1 }, 300);
      });
    } else if ($(".events_items")[0] !== undefined) {
      console.log(".events_items");
      $(".events_items")
        .find(".events_item .events_item_link")
        .each(function (index, item) {
          if (!$(this).closest(".events_item").hasClass("inc__past-event--index")) {
            const url = $(this).attr("href");

            ajaxGetContent(url, index);
          }
        });

      // When all AJAX requests are done
      $.when.apply($, requests).done(function () {
        // Append all the content in order once all AJAX requests are complete
        $(".events_items")
          // .empty()

          .append(`<div class="inc__event-card--wrapper">` + contentForFront.join("") + "</div>");
        $(".inc__new-calendar--wrapper").removeClass("inc__ajax-loading");
        $(".inc__event-card--wrapper").animate({ opacity: 1 }, 300);
      });
      $(".events_items").closest(".article_content_block").find(".btn.btn-simple-filled .inc__ripple-text").text("explore all events");
    }
  }
}

export function getEventsOnHomepage() {
  if ($(".index_1")[0] !== undefined) {
    $(document).ready(function () {
      if ($(".events_item")[0] !== undefined) {
        makeAjaxCall();
      }
    });
  }
}

export function getEventsInfo() {
  if ($("body[class^='events_']").length && !$("body[class^='events_item']").length) {
    console.log("events_");
    var events = $(".events_item"); // All event items
    var eventsContainer = $(".events_items"); // The parent container
    var existingYears = [];
    var existingMonthYear = [];
    var groupedEvents = {};

    // Get current date and format it as MM/YYYY
    var currentDate = new Date();
    var currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Ensure two digits for month
    var currentYear = currentDate.getFullYear();
    var currentMonthYear = currentYear + currentMonth; // Combine year and month for easier comparison (YYYYMM)

    // Loop through each event and group them by month/year
    events.each(function () {
      var eventDate = $(this).find(".events_item_date").first().text().trim(); // Ensure only the first date is selected
      var dateParts = eventDate.split("/");
      var day = dateParts[0];
      var month = dateParts[1];
      var year = dateParts[2];
      var monthYear = year + month; // Format as YYYYMM for easier comparison

      // Store unique years and month/year combinations
      if (!existingYears.includes(year)) {
        existingYears.push(year);
      }
      if (!existingMonthYear.includes(monthYear)) {
        existingMonthYear.push(monthYear);
      }

      // Group events by month/year
      if (!groupedEvents[monthYear]) {
        groupedEvents[monthYear] = [];
      }
      groupedEvents[monthYear].push(this);
    });

    // Clear the original container
    eventsContainer.empty();

    // Sort the existing month/year combinations (future first, past last)
    existingMonthYear.sort(function (a, b) {
      return parseInt(a) - parseInt(b); // Compare as integers for correct chronological sorting
    });

    var foundActiveMonth = false; // To check if active month is found
    var closestPastMonthYear = null;

    // Loop through grouped events and append them, also finding the active month
    $.each(groupedEvents, function (monthYear, events) {
      var groupDiv = $('<div class="inc__event__groupped" data-month-year="' + monthYear + '"></div>');
      $.each(events, function (index, event) {
        groupDiv.append(event);
      });

      // Check if the current month/year or a future month exists
      if (!foundActiveMonth) {
        if (parseInt(monthYear) >= parseInt(currentMonthYear)) {
          groupDiv.attr("data-activemonth", "true");
          foundActiveMonth = true; // Set flag to true, so no more future checks are made
          $(".inc__event__groupped[data-activemonth='true']")
            .find("events_item a")
            .each(function () {});
        } else {
          closestPastMonthYear = monthYear; // Keep track of the closest past month/year
        }
      }

      eventsContainer.append(groupDiv);
    });

    // If no future month found, apply active class to closest past month
    if (!foundActiveMonth && closestPastMonthYear) {
      $('[data-month-year="' + closestPastMonthYear + '"]').attr("data-activemonth", "true");
    }

    var monthYear = $('[data-activemonth="true"]').attr("data-month-year");
    // Extract year and month into separate variables
    const currentDisplayYear = monthYear.substring(0, 4); // First 4 characters for the year
    const currentDisplaMonth = monthYear.substring(4, 6); // Next 2 characters for the month
    const calContainerNew = `
    <div class="inc__new-calendar--wrapper">
  <div class="inc__new-calendar--year--container">
    
  </div>

  <div class="inc__new-calendar--month--container">
    <span class="inc__new-calendar--month" data-monthindex="01" data-year="${currentYear}" data-monthyearcheck="${currentDisplayYear}01">JAN</span>
    <span class="inc__new-calendar--month" data-monthindex="02" data-year="${currentYear}" data-monthyearcheck="${currentDisplayYear}02">FEB</span>
    <span class="inc__new-calendar--month" data-monthindex="03" data-year="${currentYear}" data-monthyearcheck="${currentDisplayYear}03">MAR</span>
    <span class="inc__new-calendar--month" data-monthindex="04" data-year="${currentYear}" data-monthyearcheck="${currentDisplayYear}04">APR</span>
    <span class="inc__new-calendar--month" data-monthindex="05" data-year="${currentYear}" data-monthyearcheck="${currentDisplayYear}05">MAY</span>
    <span class="inc__new-calendar--month" data-monthindex="06" data-year="${currentYear}" data-monthyearcheck="${currentDisplayYear}06">JUN</span>
    <span class="inc__new-calendar--month" data-monthindex="07" data-year="${currentYear}" data-monthyearcheck="${currentDisplayYear}07">JUL</span>
    <span class="inc__new-calendar--month" data-monthindex="08" data-year="${currentYear}" data-monthyearcheck="${currentDisplayYear}08">AUG</span>
    <span class="inc__new-calendar--month" data-monthindex="09" data-year="${currentYear}" data-monthyearcheck="${currentDisplayYear}09">SEP</span>
    <span class="inc__new-calendar--month" data-monthindex="10" data-year="${currentYear}" data-monthyearcheck="${currentDisplayYear}10">OCT</span>
    <span class="inc__new-calendar--month" data-monthindex="11" data-year="${currentYear}" data-monthyearcheck="${currentDisplayYear}11">NOW</span>
    <span class="inc__new-calendar--month" data-monthindex="12" data-year="${currentYear}" data-monthyearcheck="${currentDisplayYear}12">DEC</span>
  </div>
</div>

    `;
    $(".title_big").after(calContainerNew);

    existingYears.reverse();

    // Loop through the reversed array and append each year
    existingYears.forEach(function (year) {
      // $(".inc__new-calendar--year--container").append(
      //   `<span class="inc__new-calendar--year"  data-currentmonthdisplay="${currentDisplaMonth}" data-year="${year}">${year}</span>`
      // );

      // Check if the container has the specific class
      if ($("body").hasClass("events_105")) {
        // If it has class1, add all years
        $(".inc__new-calendar--year--container").append(
          `<span class="inc__new-calendar--year"  data-currentmonthdisplay="${currentDisplaMonth}" data-year="${year}">${year}</span>`
        );
      } else if ($("body").hasClass("events_46")) {
        // If it has class2, add only the current year and future years
        if (year >= currentYear) {
          $(".inc__new-calendar--year--container").append(
            `<span class="inc__new-calendar--year"  data-currentmonthdisplay="${currentDisplaMonth}" data-year="${year}">${year}</span>`
          );
        }
      }
    });

    $(`.inc__new-calendar--year[data-year="${currentDisplayYear}"]`).addClass("inc__active-year");
    $(`.inc__new-calendar--month[data-monthyearcheck="${monthYear}"]`).addClass("inc__month-active");

    // Go through each inc__new-calendar--month element
    $(".inc__new-calendar--month").each(function () {
      // Get the value of data-monthyearcheck
      var monthYearCheck = $(this).attr("data-monthyearcheck");

      // Check if the monthYearCheck exists in the existingMonthYear array
      if (!existingMonthYear.includes(monthYearCheck)) {
        // If it doesn't exist, add the class inc__no-month
        $(this).addClass("inc__no-month");
      }
    });

    // handle on click functions for year

    $(".inc__new-calendar--year").on("click", function () {
      $(".inc__new-calendar--year").removeClass("inc__active-year");

      $(this).addClass("inc__active-year");
      const yearToDisplay = $(this).attr("data-year");
      const monthToDisplay = $(this).attr("data-currentmonthdisplay");
      const monthYearTargetDefault = yearToDisplay + monthToDisplay;

      $(".inc__new-calendar--month").each(function () {
        $(this).attr("data-monthyearcheck", yearToDisplay + $(this).attr("data-monthindex"));
      });

      $(".inc__new-calendar--month").attr("data-year", $(this).attr("data-year"));
      $(".inc__new-calendar--month").removeClass("inc__no-month inc__month-active");

      $(".inc__new-calendar--month").each(function () {
        const monthYearCheck = $(this).attr("data-year") + $(this).attr("data-monthindex");
        if (!existingMonthYear.includes(monthYearCheck)) {
          // If it doesn't exist, add the class inc__no-month
          $(this).addClass("inc__no-month");
        }
      });

      if (!$(`span[data-monthindex="${monthToDisplay}"]`).hasClass("inc__no-month")) {
        $(`.inc__event__groupped`).removeAttr("data-activemonth");

        $(`.inc__event__groupped[data-month-year="${monthYearTargetDefault}"]`).attr("data-activemonth", "true");
        $(`.inc__new-calendar--month[data-monthindex='${monthToDisplay}']`).addClass("inc__month-active");
        $(this).addClass("inc__month-active");
      } else {
        $(`.inc__event__groupped`).removeAttr("data-activemonth");

        // Target the starting span with data-monthindex="05"
        var currentSpan = $(`span[data-monthindex="${monthToDisplay}"]`);

        // Flag to indicate if we need to check backwards
        var foundValidSpan = false;

        // Check forwards first
        currentSpan.nextAll("span").each(function (index, item) {
          if (!$(this).hasClass("inc__no-month")) {
            const newTargetMonth = $(this).attr("data-monthindex");
            const newTargetYearMonth = yearToDisplay + newTargetMonth;
            $(`.inc__event__groupped[data-month-year="${newTargetYearMonth}"]`).attr("data-activemonth", "true");
            $(this).addClass("inc__month-active");

            foundValidSpan = true;
            return false; // Break the loop when found
          }
        });

        // If no valid span found in forward direction, check backwards
        if (!foundValidSpan) {
          currentSpan.prevAll("span").each(function () {
            if (!$(this).hasClass("inc__no-month")) {
              return false; // Break the loop when found
            }
          });
        }
      }
    });

    // handle on click functinos for month

    $(".inc__new-calendar--month").on("click", function () {
      $(".inc__event__groupped").removeAttr("data-activemonth");
      $(`.inc__event__groupped[data-month-year="${$(this).attr("data-monthyearcheck")}"]`).attr("data-activemonth", "true");
      $(".inc__new-calendar--year").attr("data-currentmonthdisplay", $(this).attr("data-monthindex"));
      $(".inc__new-calendar--month").removeClass("inc__month-active");
      $(this).addClass("inc__month-active");
    });
  }
}

export function startEventObserver() {
  if ($("body[class^='events_']").length) {
    let initialAjaxCall = false;

    // Monitor for DOM updates using MutationObserver
    const observer = new MutationObserver(function (mutationsList, observer) {
      mutationsList.forEach(function (mutation) {
        // Check if the mutation is a new node being added or an attribute change
        if (mutation.type === "childList") {
          // Handle new nodes added
          mutation.addedNodes.forEach(function (node) {
            if (node.nodeType === 1) {
              // Ensure it's an element node
              const activemonth = node.getAttribute("data-activemonth");
              if (activemonth === "true" && !initialAjaxCall) {
                makeAjaxCall();
              }
            }
          });
        } else if (mutation.type === "attributes" && mutation.attributeName === "data-activemonth") {
          // Handle attribute changes
          const activemonth = mutation.target.getAttribute("data-activemonth");
          if (activemonth === "true" && !initialAjaxCall) {
            makeAjaxCall();
          }
        }
      });
    });

    // Start observing the parent element where dynamic elements are added
    const parentElement = document.body; // Or the specific parent container
    observer.observe(parentElement, {
      attributes: true, // Watch for attribute changes
      childList: true, // Watch for new nodes
      subtree: true, // Ensure to monitor the entire subtree
      attributeFilter: ["data-activemonth"], // Filter only for changes to 'data-activemonth'
    });
  }
}
// // adjust event item content height to match the highest content in the list

//   if ($(".events_itm_content_block")[0] !== undefined) {
//     let heighestContentBlock = 0;
//     $(".events_itm_content_block").each(function () {
//       if ($(this).outerHeight() > heighestContentBlock) {
//         heighestContentBlock = $(this).outerHeight();
//       }
//     });

//     $(".events_itm_content_block").each(function () {
//       $(this).css("height", heighestContentBlock + "px");
//     });
//   }

//   if ($(".events_itm")[0] !== undefined) {
//     const events = $(".events_itm");
//     events.each(function () {
//       if ($(this).find(".events_itm_past_block")[0] !== undefined) {
//         $(this).addClass("inc__past-event");
//         $(this).find(".events_itm_past_block").remove();
//         $(this).find(".events_itm_date_block").append(`<p class="inc__past-event--text">past</p>`);
//       }
//     });
//   }
